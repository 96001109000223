import React from "react"
import * as styles from "./Services.module.css"

import specialities from "econify/data/specialities.json"
import Micromark from "../../components/Micromark"

const Services = (): JSX.Element => (
  <div className={styles.section} id="services">
    <h2>Services</h2>
    <hr />
    <div className={styles.desc}>
      {specialities.map(
        ({ title, description }: { title: string; description: string }) => {
          return (
            <div className={styles.item} key={title}>
              <Micromark className={styles.title} as="h3" markdown={title} />
              <Micromark
                className={styles.description}
                markdown={description}
              />
            </div>
          )
        }
      )}
    </div>
    <div className={styles.line} />
  </div>
)

export default Services
