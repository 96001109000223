import React from "react"
import * as styles from "./Intro.module.css"

class Intro extends React.Component {
  render() {
    return (
      <div>
        <div>
          <div>
            <p className={styles.tag}>
              Econify builds the fastest websites and applications in the world,
              for the top companies in the world.
            </p>
            <hr className={styles.hr} />
          </div>
        </div>
      </div>
    )
  }
}

export default Intro
