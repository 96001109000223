// extracted by mini-css-extract-plugin
export var achievement = "Achievements-module--achievement--4466b";
export var achievementImage = "Achievements-module--achievementImage--28d0e";
export var award = "Achievements-module--award--ee20b";
export var bottom = "Achievements-module--bottom--307d8";
export var description = "Achievements-module--description--7a17d";
export var flipCard = "Achievements-module--flipCard--52537";
export var flipCardBack = "Achievements-module--flipCardBack--63b21";
export var flipCardContainer = "Achievements-module--flipCardContainer--220ab";
export var flipCardFront = "Achievements-module--flipCardFront--20f9d";
export var flipCardInner = "Achievements-module--flipCardInner--a3e86";
export var flipped = "Achievements-module--flipped--e9b58";
export var header = "Achievements-module--header--dd37d";
export var img = "Achievements-module--img--57db8";
export var line = "Achievements-module--line--36729";
export var logo = "Achievements-module--logo--f1d70";
export var plusSign = "Achievements-module--plusSign--23ce8";
export var seeMore = "Achievements-module--seeMore--770e1";
export var title = "Achievements-module--title--cc250";
export var vix = "Achievements-module--vix--5fa71";