import React, { useMemo } from "react"
import { withPrefix } from "gatsby"

import Carousel from "../../components/Carousel/Carousel"
import Micromark from "../../components/Micromark"

import * as styles from "./Testimonials.module.css"

import testimonials from "econify/data/testimonials.json"

interface ITestimonial {
  id: string
  companyLogo: string
  text: string
  author: string
  position: string
}

function Testimonials() {
  const testimonialElements = useMemo(() => {
    return (testimonials as ITestimonial[]).map(testimonial => (
      <div key={testimonial.id} className={styles.testimonial}>
        <img
          className={styles.testimonialLogo}
          src={withPrefix(testimonial.companyLogo)}
          alt={testimonial.id}
          loading="lazy"
        />
        <Micromark
          className={styles.testimonialText}
          markdown={testimonial.text}
        />
        <div className={styles.testimonialAuthor}>
          <Micromark markdown={testimonial.author} />
          <Micromark markdown={testimonial.position} />
        </div>
      </div>
    ))
  }, [withPrefix])

  return (
    <div className={styles.testimonialsContainer} id="testimonials">
      <h2>Testimonials</h2>
      <hr />
      <Carousel className={styles.desktopCarousel} cardsPerSlide={2}>
        {testimonialElements}
      </Carousel>
      <Carousel className={styles.mobileCarousel}>
        {testimonialElements}
      </Carousel>
      <div className={styles.testimonialLine}></div>
    </div>
  )
}

export default Testimonials
