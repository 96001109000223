import React, { useEffect, useState } from "react"
import { withPrefix } from "gatsby"

import * as styles from "./Carousel.module.css"

interface CarouselProps {
  className?: string
  children: React.ReactNode[]
  cardsPerSlide?: number
}

const Carousel: React.FC<CarouselProps> = ({
  className,
  children,
  cardsPerSlide = 1,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

  useEffect(() => {
    startSliding()
    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [children])

  const startSliding = () => {
    const timer = setInterval(() => {
      setCurrentSlide(
        prevSlide =>
          (prevSlide + 1) %
          Math.ceil(React.Children.count(children) / cardsPerSlide)
      )
    }, 6000)
    setIntervalId(timer)
  }

  const handleIndicatorClick = (i: number) => {
    if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(null)
    }
    setCurrentSlide(i)
    startSliding()
  }

  const stopSliding = () => {
    if (intervalId) {
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }

  const resumeSliding = () => {
    if (!intervalId) {
      startSliding()
    }
  }

  const totalSlides = Math.ceil(React.Children.count(children) / cardsPerSlide)

  return (
    <div
      className={`${styles.carouselContainer} ${className}`}
      onMouseEnter={stopSliding}
      onMouseLeave={resumeSliding}
    >
      <button
        className={styles.arrow}
        disabled={currentSlide === 0}
        onClick={() => handleIndicatorClick(currentSlide - 1)}
      >
        <img
          alt="left arrow icon"
          src={withPrefix("/images/arrow-left.svg")}
          className={styles.arrow}
          loading="lazy"
        />
      </button>
      <div className={styles.carouselSlidesContainer}>
        <div
          className={styles.carouselSlides}
          style={{
            display: "flex",
            transform: `translateX(-${100 * currentSlide}%)`,
          }}
        >
          {React.Children.map(children, (child, index) => (
            <div
              key={index}
              className={styles.carouselSlide}
              style={{
                flex: `1 0 ${100 / cardsPerSlide}%`,
              }}
            >
              {child}
            </div>
          ))}
        </div>
        <div className={styles.carouselIndicators}>
          {Array(totalSlides)
            .fill(0)
            .map((_, i) => (
              <button
                key={i}
                type="button"
                className={`${styles.carouselIndicator} ${
                  i === currentSlide ? styles.active : ""
                }`}
                onClick={() => handleIndicatorClick(i)}
              />
            ))}
        </div>
      </div>
      <button
        className={styles.arrow}
        type="button"
        disabled={currentSlide === totalSlides - 1}
        onClick={() => handleIndicatorClick(currentSlide + 1)}
      >
        <img
          alt="right arrow icon"
          src={withPrefix("/images/arrow-right.svg")}
          className={styles.arrow}
          loading="lazy"
        />
      </button>
    </div>
  )
}

export default Carousel
