import React, { Component, SyntheticEvent } from "react"
import Recaptcha from "react-recaptcha"
import { InView } from "react-intersection-observer"
import * as styles from "./ContactUs.module.css"
import { CONTACT_SUBMISSION_URL } from "econify/contstants"

type ContactUsState = {
  companyInfo: {
    sectionHeading: string
    locations: object
  }
  email: string
  message: string
  recaptchaToken: string
  errorsMsg: { [key: string]: string }
  emailError: boolean
  messageError: boolean
  submitting: boolean
  sendSuccessful: boolean
  submitMsg: string
  close: boolean
  disableSubmit: boolean
}
class ContactUs extends Component<{}, ContactUsState> {
  state = {
    companyInfo: {
      sectionHeading: "",
      locations: {},
    },
    email: "",
    message: "",
    recaptchaToken: "",
    errorsMsg: {
      email: "",
      message: "",
    },
    emailError: false,
    messageError: false,
    submitting: false,
    sendSuccessful: false,
    submitMsg: "",
    close: false,
    disableSubmit: true,
  }

  componentWillUnmount = (): void => {
    const scriptId = "recaptcha-script"
    let script = document.getElementById(scriptId)
    if (script) {
      script.remove()
    }
  }

  handleSubmit = (event: SyntheticEvent): void => {
    event.preventDefault()

    if (this.state.recaptchaToken && this.validateForm()) {
      this.setState({ submitting: true })

      fetch(CONTACT_SUBMISSION_URL, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          email: this.state.email,
          message: this.state.message,
          recaptchaToken: this.state.recaptchaToken,
        }),
      }).then((response: any) => {
        if (response.status !== 200) {
          this.setState({
            submitMsg: "Error sending message. Please try again later.",
            close: true,
            sendSuccessful: false,
            submitting: false,
          })

          return
        }

        this.resetForm()
        this.setState({
          submitMsg: `Message sucessfully sent. We'll be in touch shortly!`,
          close: true,
          sendSuccessful: true,
          submitting: false,
        })
      })
    }
  }

  validateForm = (): boolean | void => {
    let valid = true

    const { email, message } = this.state
    const errors: any = {}
    if (!email.length) {
      this.setState({ emailError: true })
      errors["email"] = "Missing email address"
      valid = false
    } else {
      this.setState({ emailError: false })
    }
    if (!message.length) {
      this.setState({ messageError: true })
      errors["message"] = "Missing message body"
      valid = false
    } else {
      this.setState({ messageError: false })
    }
    this.setState({
      errorsMsg: errors,
    })
    return valid
  }

  resetForm = (): void => {
    this.setState({ email: "", message: "" })
  }

  onEmailChange = (event: SyntheticEvent): void => {
    this.setState({ email: (event.target as HTMLTextAreaElement).value })
  }

  onMessageChange = (event: SyntheticEvent): void => {
    this.setState({ message: (event.target as HTMLTextAreaElement).value })
  }

  recaptchaLoaded = (): void => {}

  verifyCallback = (recaptchaToken: string): JSX.Element | void => {
    if (recaptchaToken) {
      this.setState({ recaptchaToken, disableSubmit: false })
    } else {
      this.setState({ disableSubmit: true })
    }
  }

  expiredCallback = (): void => {
    this.setState({ recaptchaToken: "", disableSubmit: true })
  }

  closeEmailHandler = (): void => {
    this.setState({ emailError: false })
  }

  closeMessageHandler = (): void => {
    this.setState({ messageError: false })
  }

  closeSuccessfulHandler = (): void => {
    this.setState({ close: false })
  }

  successfulMessage = (): JSX.Element => {
    if (!this.state.submitMsg.length) {
      return <div className={styles.isHidden}>{this.state.submitMsg}</div>
    } else {
      const color = this.state.sendSuccessful ? "success" : "error"
      return (
        <div className={this.state.close ? styles[color] : styles.isHidden}>
          {this.state.submitMsg}
        </div>
      )
    }
  }

  loadScript() {
    const scriptId = "recaptcha-script"
    if (window !== undefined) {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script")
        script.id = scriptId
        script.src = "https://www.google.com/recaptcha/api.js?render=explicit"
        window.document.body.appendChild(script)
      }
    }
  }

  render(): JSX.Element {
    const LocationItems = Object.entries(this.state.companyInfo.locations).map(
      ([locationKey, locationName]) => {
        return <li key={locationKey}>{locationName}</li>
      }
    )

    const {
      emailError,
      messageError,
      sendSuccessful,
      submitting,
      companyInfo,
    } = this.state
    const submitBtnProperties = (submitting && { disabled: true }) || {}

    return (
      <InView
        as="div"
        onChange={(inView, entry) => {
          if (inView) this.loadScript()
        }}
      >
        <div className={styles.section} id="contact">
          <h2>Contact</h2>
          <hr />
          <div className={styles.desc}>
            <p>Talk to us to get started!</p>
          </div>
          <div className={styles.wrap}>
            <div className={styles.info}>
              <p>hello@econify.com</p>
              <hr />
              <h3 className={styles.locationHeading}>
                {companyInfo.sectionHeading}
              </h3>
              <ul className={styles.locationList}>{LocationItems}</ul>
            </div>
            {sendSuccessful && (
              <div className={styles.form}>{this.successfulMessage()}</div>
            )}
            {!sendSuccessful && (
              <form
                onSubmit={this.handleSubmit.bind(this)}
                method="POST"
                className={styles.form}
              >
                <div
                  className={
                    emailError
                      ? styles.error
                      : [styles.error, styles.isHidden].join(" ")
                  }
                >
                  {this.state.errorsMsg.email}
                  <span
                    className={styles.errorBtn}
                    onClick={this.closeEmailHandler}
                  >
                    x
                  </span>
                </div>
                <div
                  className={
                    messageError
                      ? styles.error
                      : [styles.error, styles.isHidden].join(" ")
                  }
                >
                  {this.state.errorsMsg.message}
                  <span
                    className={styles.errorBtn}
                    onClick={this.closeMessageHandler}
                  >
                    x
                  </span>
                </div>
                <div>
                  <input
                    aria-label="email address"
                    type="email"
                    required
                    className={styles.formControl}
                    value={this.state.email}
                    placeholder={"Your email"}
                    onChange={this.onEmailChange.bind(this)}
                  />
                </div>
                <div>
                  <textarea
                    aria-label="message to us"
                    rows={5}
                    className={styles.formControl}
                    value={this.state.message}
                    placeholder="How can we help?"
                    onChange={this.onMessageChange.bind(this)}
                  />
                </div>
                <div className={styles.btnRow}>
                  <button
                    type="submit"
                    className={styles.submitBtn}
                    {...submitBtnProperties}
                    disabled={this.state.disableSubmit || this.state.submitting}
                  >
                    SEND
                  </button>
                  <div className={styles.recaptcha}>
                    {process.env.SITE_KEY && (
                      <Recaptcha
                        sitekey={process.env.SITE_KEY}
                        render="explicit"
                        onloadCallback={this.recaptchaLoaded.bind(this)}
                        verifyCallback={this.verifyCallback.bind(this)}
                        expiredCallback={this.expiredCallback.bind(this)}
                      />
                    )}
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </InView>
    )
  }
}

export default ContactUs
