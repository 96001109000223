import React, { Component } from "react"
import Intro from "./routes/Intro"
import Testimonials from "./routes/Testimonials"
import Achievements from "./routes/Achievements"
import Client from "./routes/Clients"
import Services from "./routes/Services"
import ContactUs from "./routes/ContactUs"
import ScrollToTop from "./components/Scroller"
import "./index.css"
import * as styles from "./App.module.css"
import SEO from "./components/seo"
import Header from "./components/Header"
import Footer from "./components/Footer"

class App extends Component {
  render(): JSX.Element {
    return (
      <>
        <SEO />
        <Header />
        <div className={styles.container}>
          <Intro />
          <Achievements />
          <Services />
          <Testimonials />
          <Client />
          <ContactUs />
          <ScrollToTop />
        </div>
        <Footer />
      </>
    )
  }
}

export default App
