import React, { Component } from "react"
import cx from "classnames"
import * as styles from "./Clients.module.css"
import clientDB from "econify/data/clientsdb.json"
import { withPrefix } from "gatsby"

interface IClient {
  name: string
  wide: boolean
}

class Clients extends Component<{}> {
  render(): JSX.Element {
    const logos = clientDB.map((client: IClient, idx) => {
      return (
        <img
          alt={`a logo of one of our clients: ${client.name}`}
          src={withPrefix(`/images/clients/${client.name}.svg`)}
          key={`${client.name}-${idx}`}
          className={cx(styles.clients, { [styles.wide]: client.wide })}
          loading="lazy"
        />
      )
    })
    return (
      <div className={styles.clientsContainer} id="clients">
        <h2>Clients</h2>
        <hr />
        <div className={styles.section}>{logos}</div>
        <div className={styles.line} />
      </div>
    )
  }
}

export default Clients
