import { withPrefix } from "gatsby"
import React, { Component } from "react"
import * as styles from "./ScrollToTop.module.css"

type ScrollState = {
  isVisible: boolean
}

class ScrollToTop extends Component<{}, ScrollState> {
  constructor(props: object) {
    super(props)
    this.state = {
      isVisible: false,
    }
    this.toggleVisibility = this.toggleVisibility.bind(this)
  }

  componentDidMount(): void {
    document.addEventListener("scroll", this.toggleVisibility)
  }

  componentWillUnmount(): void {
    document.removeEventListener("scroll", this.toggleVisibility)
  }

  toggleVisibility(): void {
    if (window.pageYOffset > 100) {
      this.setState({
        isVisible: true,
      })
    } else {
      this.setState({
        isVisible: false,
      })
    }
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  render(): JSX.Element {
    const { isVisible } = this.state
    return (
      <div className={styles.scrollToTop}>
        {isVisible && (
          <div onClick={(): void => this.scrollToTop()}>
            <img
              alt="an arrow up icon"
              src={withPrefix("/images/arrow-up.svg")}
              className={styles.arrow}
              loading="eager"
            />
          </div>
        )}
      </div>
    )
  }
}

export default ScrollToTop
