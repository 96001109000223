import React, { useState, useEffect } from "react"
import cx from "classnames"
import { withPrefix } from "gatsby"

import achievementsData from "econify/data/achievements.json"
import Micromark from "../../components/Micromark"

import * as styles from "./Achievements.module.css"

interface IAchievement {
  client: string
  title: string
  description: string
  video: boolean
  image: boolean
  displayAward: boolean
}

interface IAchievementUI extends IAchievement {
  flipped: boolean
}

const Achievements = (): JSX.Element => {
  const resolved: Record<string, IAchievementUI> = {}
  const achievements = (achievementsData as IAchievement[]).reduce(
    (resolved, achievement) => {
      resolved[achievement.title] = { ...achievement, flipped: false }
      return resolved
    },
    resolved
  )

  const [achievementsUI, setAchievementsUI] = useState(achievements)

  useEffect(() => {
    // Flip the first card after .5 seconds
    const firstAchievement = achievementsUI[Object.keys(achievementsUI)[0]]

    const timer = setTimeout(() => {
      flipCard(firstAchievement, true)
      // Flip the first card back after 1.5 seconds
      setTimeout(() => {
        flipCard(firstAchievement, false)
      }, 1500)
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  const flipCard = (achievement: IAchievementUI, flipped: boolean) => {
    setAchievementsUI(prevState => {
      const newState = { ...prevState }
      newState[achievement.title] = {
        ...achievement,
        flipped: flipped,
      }
      return newState
    })
  }

  return (
    <>
      <div className={styles.flipCardContainer}>
        {Object.values(achievementsUI).map((achievement, idx: number) => {
          const clientName = achievement.client
          const award = achievement.award
          const flipped = achievement.flipped ? styles.flipped : ""
          return (
            <button
              key={`${clientName}-${idx}`}
              className={styles.flipCard}
              type="button"
              onClick={() => flipCard(achievement, !achievement.flipped)}
            >
              <div className={cx(styles.flipCardInner, flipped)}>
                <div className={styles.flipCardFront}>
                  <div
                    key={`${clientName}-${idx}`}
                    className={styles.achievement}
                  >
                    <div className={styles.header}>
                      <Micromark
                        className={styles.title}
                        markdown={achievement.title}
                      />
                    </div>
                    <Micromark
                      className={styles.description}
                      markdown={achievement.description}
                    />
                    <div className={styles.bottom}>
                      <img
                        alt={`a logo of one of our clients: ${clientName}`}
                        src={withPrefix(
                          `/images/achievements/${clientName}_logo.svg`
                        )}
                        key={`${clientName}-logo-${idx}`}
                        className={cx(styles.logo)}
                      />
                      <span className={styles.seeMore}>See More</span>
                    </div>
                  </div>
                </div>
                <div className={cx(styles.flipCardBack, styles[clientName])}>
                  {achievement.flipped && achievement.video && (
                    <video
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: "contain",
                        background: "#242225",
                      }}
                      autoPlay
                      muted
                      loop
                      playsInline
                    >
                      <source
                        src={withPrefix(
                          `/videos/achievements/${clientName}.mp4`
                        )}
                        type="video/mp4"
                      />
                      Sorry, your browser doesn't support embedded videos.
                    </video>
                  )}
                  {achievement.flipped && achievement.image && (
                    <img
                      alt={`${clientName} achievement`}
                      src={withPrefix(`/images/achievements/${clientName}.png`)}
                      key={`${clientName}-achievement-${idx}`}
                      className={cx(
                        styles.achievementImage,
                        styles[clientName]
                      )}
                      loading="lazy"
                    />
                  )}
                  {achievement.flipped && achievement.displayAward && (
                    <img
                      alt={`${clientName} award`}
                      src={withPrefix(`/images/${award}.png`)}
                      key={`${clientName}-award-${idx}`}
                      className={cx(styles.award)}
                      loading="lazy"
                    />
                  )}
                </div>
              </div>
            </button>
          )
        })}
      </div>
      <img
        alt=""
        className={styles.line}
        src={withPrefix("/images/line_arrow_b.svg")}
        loading="lazy"
      />
    </>
  )
}

export default Achievements
